import Header from "../Homepage/header";
import Footer from "../Homepage/Footer";
import arrowLeft from "../../assets/img/icons/arrow_left.svg";
import arrowRight from "../../assets/img/icons/arrow_right.svg";
import sample5 from "../../assets/img/sample/sample_05.jpg";
import sample4 from "../../assets/img/sample/sample_04.jpg";
import person24 from "../../assets/img/members/person_24.svg";
import person25 from "../../assets/img/members/person_25.png";
import { Link, useNavigate } from "react-router-dom";

const MemberDetails = () => {
  const navigate = useNavigate();
  const handleRedirect = (e, path = "/registration") => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };

  const handleChatBot = (e) => {
    e.preventDefault();
    window.zE("messenger", "open");
  };

  return (
    <>
      <Header />
      <div className="academicSection innerPages">
        <div className="containWrap">
          <a
            onClick={(e) => handleRedirect(e, "/advisory")}
            className="memberBack"
          >
            Back
            <b>
              <img src={arrowLeft} alt="#" />
            </b>
          </a>
        </div>

        <div className="memberBar">
          <div className="containWrap">
            <div className="memberBio">
              <div className="memberBio-img">
                {" "}
                <img src={person25} alt="#" />
              </div>
              <div className="memberBio-intro">
                <h1>Jeffrey T. Cuthbertson</h1>
                <p> 
                Maintains a boutique law practice  <br />
                Member, State Bar of Michigan <br />
                Member, United States Naval Institute 
                </p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="containWrap">
            <div className="memberRow">
              <div className="memberLeft">
                <div className="memberBio-sum">
                  <p>
                  Jeffrey T. Cuthbertson, is a graduate of The University of
Michigan where he received his B.A. in political science. He
embarked on successful business career in international supply
chain mergers and acquisition and business integration projects
before attending law school.
                  </p>
                  <p>
                  Mr. Cuthbertson is a graduate of Western Michigan
University Thomas M. Cooley Law School where he received an
Honors Tuition Scholarship and graduated just two years after
enrolling. He was Clerk to the Michigan Legislature’s Law
Revision Commission advising on economic development policy
and legislative matters.
                  </p>
                  <p>
                  He was elected to the Rochester, Michigan City Council while pursuing his law degree
and became the youngest Mayor in City history and served five annual terms in that role during
twelve years of Council service to his hometown. Mr. Cuthbertson’s commitment to community
extended to service on the Oakland University INCubator Investment Board of Review,
Rochester Planning Commission, Rochester Downtown Development Authority, Michigan
Association of Mayors, and Michigan Association of Planners.
                  </p>
                  <p>
                  He has worked as an attorney in private practice for several years at a large Michigan law
firm focusing on land use and real estate development projects, municipal law, and estate
planning matters. He also later joined a private equity firm in an executive role
                  </p>
                  <p>
                  Mr. Cuthbertson now maintains a boutique law practice focused upon land use and real
estate development matters, advising closely held companies, business transactions, and aviation
law. He is a member of the State Bar of Michigan, United States District Court for the Eastern
District of Michigan, and the United States Supreme Court Bar.
                  </p>
                  <p>
                  Mr. Cuthbertson is an avid reader of history and a member of the United States Naval
                  Institute. He is married and a proud father of two daughters.
                  </p>
                </div>
                <div className="member-back">
                  <a
                    onClick={(e) => handleRedirect(e, "/advisory")}
                    className="memberBack"
                  >
                    Back
                    <b>
                      <img src={arrowLeft} alt="#" />
                    </b>
                  </a>
                  <a
                    onClick={(e) =>
                      handleRedirect(e, "/member-details/sam")
                    }
                    className="memberBack"
                  >
                    Next
                    <b>
                      <img src={arrowRight} alt="#" />
                    </b>
                  </a>
                </div>
              </div>
              <div className="memberRight">
                <div className="checkOut">
                  <h2>Also Check Out</h2>
                  <div className="checkBox">
                    <div className="checkBox-img">
                      <img src={sample5} alt="#" />
                    </div>
                    <div className="checkBox-info">
                      <h3>Step into Greatness</h3>
                      <p>
                        Your words have the power to change the world. Register
                        as a Peer Reviewer and/or a Publishing Editor.
                      </p>
                    </div>
                    <Link to="/registration">REGISTER NOW</Link>
                  </div>
                  <div className="checkBox">
                    <div className="checkBox-img">
                      <img src={sample4} alt="#" />
                    </div>
                    <div className="checkBox-info">
                      <h3>Meet the Dream Catchers</h3>
                      <p>
                        Who are brave enough to dream of a different future for
                        what is today referred to as, the developing world.
                      </p>
                    </div>
                    <Link to="/teams">THE TEAM</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="pullUp"
            onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <b className="icon-arrow-right" />
          </div>
          <div class="row align-items-center justify-content-between contentFooter">
            <div class="col text-end">
              <a onClick={handleChatBot} class="helpLink">
                <b class="icon-help"></b>Help
              </a>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default MemberDetails;
