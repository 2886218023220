import Header from "../Homepage/header";
import Footer from "../Homepage/Footer";
import arrowLeft from "../../assets/img/icons/arrow_left.svg";
import arrowRight from "../../assets/img/icons/arrow_right.svg";
import sample5 from "../../assets/img/sample/sample_05.jpg";
import sample4 from "../../assets/img/sample/sample_04.jpg";
import person24 from "../../assets/img/members/person_24.svg";
import person25 from "../../assets/img/members/person_25.png";
import person26 from "../../assets/img/members/person_26.png";
import { Link, useNavigate } from "react-router-dom";

const MemberDetails = () => {
  const navigate = useNavigate();
  const handleRedirect = (e, path = "/registration") => {
    e.preventDefault();
    window.scroll({ top: 0, behavior: "smooth" });
    navigate(path);
  };

  const handleChatBot = (e) => {
    e.preventDefault();
    window.zE("messenger", "open");
  };

  return (
    <>
      <Header />
      <div className="academicSection innerPages">
        <div className="containWrap">
          <a
            onClick={(e) => handleRedirect(e, "/advisory")}
            className="memberBack"
          >
            Back
            <b>
              <img src={arrowLeft} alt="#" />
            </b>
          </a>
        </div>

        <div className="memberBar">
          <div className="containWrap">
            <div className="memberBio">
              <div className="memberBio-img">
                {" "}
                <img src={person26} alt="#" />
              </div>
              <div className="memberBio-intro">
                <h1>Dr Ravi Kumar Jain</h1>
                <p> 
                Director,<br/>
                Professor of Finance & Strategy,<br/>
                Sparsh Global Business School, Greater Noida<br/>
                Also a start-up evangelist.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="containWrap">
            <div className="memberRow">
              <div className="memberLeft">
                <div className="memberBio-sum">
                  <p>
                  Dr. Ravi Kumar Jain, a start-up evangelist, is a Doctorate in Business Management and holds
Masters in Business Administration (Finance) and PGD in ICT management. Dr. Jain brings in
over 25 years of rich experience in family business management, teaching & training, research,
business consulting, coaching, mentoring and academic administration. Institution building and
championing new initiatives has been his forte.
                  </p>
                  <p>
                  He is an avid researcher with more than 150 international and national publications to his credit;
authored and edited 40+ books in the area of business management, banking & finance and ICT
management. He serves on the editorial boards of reputed international refereed journals
                  </p>
                  <p>
                  He brings in a strong business and academic network, has conducted 20+ successful international
events and conferences and has been actively engaged in business coaching, faculty training and
executive training programs at various organizations including L&T Metro, Indian railways,
several banks and IT services companies, Indian armed forces to name a few. He is an expert
trainer in case method teaching.

                  </p>
                  <p>
                  He has held various academic and administrative positions at ICFAI Research Center,
Hyderabad; Symbiosis International University, Pune; and in the faculty of Finance at IBSHyderabad campus. He was the founding director of Symbiosis Institute of Research and
Innovation at Pune; Founding Director of Symbiosis Institute of Business management (SIBM)
Hyderabad Campus; and currently he is the Founding Director of Sparsh Global Business School
(SGBS) at Greater Noida.
                  </p>
                  <p>
                  He served on the Board of Directors of a very prestigious global consortium The Decision
Sciences Institute (DSI), Houston USA in the capacity of VP for Asia Pacific Region (APDSI).
He is the immediate past president of Indian Subcontinent DSI (ISDSI) and has been on its board
for the last 6 years holding various positions. Currently he is serving on the boards / management
committees / advisor / special invitee / Mentor of prestigious industry/academic associations
such as Member Board of studies (BOS) at All India Management Association (AIMA),
Association of Indian Management schools (AIMS, Telangana), Hyderabad Management
Association (HMA), Federation of Telangana Chamber of Commerce and Industry (FTCCI), The
Associated Chambers of Commerce of India (ASSOCHAM); T-Hub; ENDNOW Foundation etc.
He has been a member of various sub-committees and work groups constituted by UGC and
AICTE (Ministry of Education, GOI) for academic policy making; BIS (Bureau of Indian
standards, Ministry of consumer Affairs Food and Public Distribution, GOI) Working group for
Sustainable Finance (ISO/TC/322). Serving on the national mirror committee of India for
Sustainable Finance Sectional Committee, MSD 18 of BIS corresponding to ISO/TC/322/WG1
since November 2020 and MSDC/WG1 Strategy Group on Development of Strategic Road Map
since July 2021.
                  </p>
                  <p>
                  Received several awards – Received Award for ''Outstanding Leadership in Institution
Building 2024'' at the LEO1 Education Excellence Awards 2024, by ObserveNow Media;
‘Excellence in Institution Building’ by Top Rankers during their 23rd Annual management
convention September 2023; ‘Dr Suresh Ghai Memorial Award for Outstanding Business
School Director 2021”, by Higher Education Forum (HEF); ‘Academician Excellence Award
2021’ by Hyderabad Management Association; ‘Best Director of B-school’ by Indian
Subcontinent Decision Science Institute (2018); ‘Best B-school in South India’ (2019) by
ASSOCHEM received by the hands of Hon’ble Past President of India , Late Shree Pranab
Mukherjee; ‘Distinguished Services Award’ by Asia Pacific Decision Science Institute; Shiksha
Rattan award (2014) by Indo-US Friendship Society, New Delhi; Researcher of the Year award
(2004) by Institute of Management and Technology, New Delhi (for Best Paper presented at Int’l
conference) received by the hands of Hon’ble Vice Precedent of India Shree Bhairon Singh
Shekhawat.
                  </p>
                  <p>His areas of research interest are corporate finance, valuations, market microstructure, Business
                  Economics and outsourcing.</p>
                </div>
                <div className="member-back">
                  <a
                    onClick={(e) => handleRedirect(e, "/advisory")}
                    className="memberBack"
                  >
                    Back
                    <b>
                      <img src={arrowLeft} alt="#" />
                    </b>
                  </a>
                  <a
                    onClick={(e) =>
                      handleRedirect(e, "/member-details/sam")
                    }
                    className="memberBack"
                  >
                    Next
                    <b>
                      <img src={arrowRight} alt="#" />
                    </b>
                  </a>
                </div>
              </div>
              <div className="memberRight">
                <div className="checkOut">
                  <h2>Also Check Out</h2>
                  <div className="checkBox">
                    <div className="checkBox-img">
                      <img src={sample5} alt="#" />
                    </div>
                    <div className="checkBox-info">
                      <h3>Step into Greatness</h3>
                      <p>
                        Your words have the power to change the world. Register
                        as a Peer Reviewer and/or a Publishing Editor.
                      </p>
                    </div>
                    <Link to="/registration">REGISTER NOW</Link>
                  </div>
                  <div className="checkBox">
                    <div className="checkBox-img">
                      <img src={sample4} alt="#" />
                    </div>
                    <div className="checkBox-info">
                      <h3>Meet the Dream Catchers</h3>
                      <p>
                        Who are brave enough to dream of a different future for
                        what is today referred to as, the developing world.
                      </p>
                    </div>
                    <Link to="/teams">THE TEAM</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="pullUp"
            onClick={(e) => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <b className="icon-arrow-right" />
          </div>
          <div class="row align-items-center justify-content-between contentFooter">
            <div class="col text-end">
              <a onClick={handleChatBot} class="helpLink">
                <b class="icon-help"></b>Help
              </a>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default MemberDetails;
